import styled, { css } from 'styled-components';

import BaseLink from 'Elements/Link/Link';

import { ReactComponent as WaveSVG } from 'Public/graphics/waves/cards/profile/wave-profile.svg';

export const ProfileStyled = styled.article<{ $border: boolean }>`
    ${({ theme, $border }) => css`
        background: ${theme.colors.secondary.hex};
        height: 100%;
        padding: 1rem 0.5rem;
        border-radius: 0.5rem;
        position: relative;
        overflow: hidden;

        ${$border &&
        css`
            border: 0.0625rem solid ${theme.colors.secondary.tints[1].hex};
        `}

        @media ${theme.breakpoints.medium.media} {
            padding: 1rem;
        }

        @media ${theme.breakpoints.xlarge.media} {
            padding: 2rem;
        }
    `}
`;

export const Inner = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        justify-content: space-between;
        z-index: 1;

        @media ${theme.breakpoints.large.media} {
            align-items: center;
        }
    `}
`;

export const Information = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 60%;

        @media ${theme.breakpoints.medium.media} {
            width: 60%;
        }
    `}
`;

export const ProfileTitle = styled.h2`
    ${({ theme }) => css`
        margin: 0;
        font-size: 1.5rem;
        line-height: 1.2;

        @media ${theme.breakpoints.large.media} {
            font-size: 2.5rem;
        }
    `}
`;

export const JobTitle = styled.h3`
    ${({ theme }) => css`
        color: ${theme.colors.primary.hex};
        margin: 0;
        font-size: 1rem;
        line-height: 1.2;

        @media ${theme.breakpoints.large.media} {
            font-size: 2rem;
        }
    `}
`;

export const Info = styled.p`
    ${({ theme }) => css`
        line-height: 1.25;
        font-family: ${theme.fonts.secondary};
        font-size: 1rem;
        margin: 0.75rem 0 0;

        @media ${theme.breakpoints.large.media} {
            font-size: 1.25rem;
            margin: 1rem 0 0;
        }
    `}
`;

export const Contacts = styled.div`
    ${({ theme }) => css`
        margin-top: 0.5rem;

        @media ${theme.breakpoints.large.media} {
            margin-top: 1rem;
        }
    `}
`;

export const ContactInformation = styled.div`
    margin-top: 0.75rem;
    display: flex;
    align-items: center;
`;

export const StyledIcon = styled.svg`
    color: ${({ theme }) => theme.colors.primary.hex};
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.75rem;
`;

export const Link = styled(BaseLink)`
    color: ${({ theme }) => theme.colors.text.hex};
`;

export const ImageWrapper = styled.div`
    ${({ theme }) => css`
        width: 7rem;
        height: 7rem;
        position: relative;
        overflow: hidden;
        display: flex;
        border-radius: 50%;
        border: 0.5rem solid ${theme.colors.secondary.tints[1].hex};
        background: ${theme.colors.secondary.hex};
        transition: all 0.3s ease;

        &:hover {
            transform: scale(1.1);
        }

        @media ${theme.breakpoints.medium.media} {
            width: 6rem;
            height: 6rem;
        }

        @media ${theme.breakpoints.large.media} {
            width: 11rem;
            height: 11rem;
        }
    `}
`;

export const Wave = styled(WaveSVG)`
    ${({ theme }) => css`
        position: absolute;
        z-index: 0;
        height: 85rem;
        top: -18rem;
        right: -17rem;
        width: 57rem;

        @media ${theme.breakpoints.large.media} {
            width: 85rem;
            height: 128rem;
            top: -27rem;
            right: -23rem;
        }
    `}
`;
